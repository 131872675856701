.data-hover {
    background: #ffffff;
    box-shadow: 0 0 35px 0 rgba(73, 80, 87, 0.15);
    white-space: nowrap;
    border-radius: 10px;
    position: fixed;
    z-index: 1000;
    @media not all and (min-resolution: 0.001dpcm) {
        min-width: 18em;
    }
    min-width: 14em;
    padding: 10px;

    .hoveredObjectData {
        .cases {
            margin: 7px;
        }

        hr {
            margin:5px 0px 10px 0px;
            border: 1px solid rgba(73, 80, 87, 0.15);

        }
    }
    .detail {
        display: flex;
        .img-detail {
            img {
                margin-left: -10px;
            }
        }
        .info-detail {
            span {
                display: block;
                margin-top: auto;
                margin-bottom: auto;
                font-style: normal;
                line-height: 1;
            }
            display: block;
        }
    }
}
