@charset "utf-8";

// Import only what you need from Bulma
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/elements/button.sass";
@import "../../node_modules/bulma/sass/elements/container.sass";
@import "../../node_modules/bulma/sass/elements/title.sass";
@import "../../node_modules/bulma/sass/form/_all.sass";
@import "../../node_modules/bulma/sass/components/navbar.sass";
@import "../../node_modules/bulma/sass/components/card.sass";
@import "../../node_modules/bulma/sass/layout/section.sass";
@import "../../node_modules/bulma/sass/grid/columns.sass";
@import "../../node_modules/bulma/sass/grid/tiles.sass";
@import "../../node_modules/bulma/sass/components/pagination.sass";
@import "../../node_modules/bulma/sass/components/tabs.sass";
@import "../../node_modules/bulma/sass/components/modal.sass";

// screen sizes
$break-large: 1024px;
$break-medium: 1023px;
$break-smaller: 800px;



body,html,#root {
  background: #242424!important;
  background: -moz-linear-gradient(top, #242424 0%, #242424 41%, #2e2e2e 49%, #2e2e2e 100%)!important;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #242424), color-stop(41%, #242424), color-stop(49%, #2e2e2e), color-stop(100%, #2e2e2e))!important;
  background: -webkit-linear-gradient(top, #242424 0%, #242424 41%, #2e2e2e 49%, #2e2e2e 100%)!important;
  background: -o-linear-gradient(top, #242424 0%, #242424 41%, #2e2e2e 49%, #2e2e2e 100%)!important;
  background: -ms-linear-gradient(top, #242424 0%, #242424 41%, #2e2e2e 49%, #2e2e2e 100%)!important;
  background: linear-gradient(to bottom, #242424 0%, #242424 41%, #2e2e2e 49%, #2e2e2e 100%)!important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#75cff0', endColorstr='#f3efe9', GradientType=0 )!important;

  font-family: 'Roboto Slab', cursive;
}

.mapboxgl-ctrl-logo,.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

body .mapboxgl-ctrl.mapboxgl-ctrl-group {
  background-color: whitesmoke!important;
  margin: 10px;
}
// custom imports
@import "custom";
@import "typography";