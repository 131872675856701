@import "Modal.scss";
@import "legend.scss";
@import "hoverPanel.scss";
@import "colors";

html,
body,
#root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
 
}
body {
    margin: 0;
    padding: 0;

    .mapboxgl-ctrl.mapboxgl-ctrl-group {
        background: transparent;
        box-shadow: 0 0 35px 0 rgba(73, 80, 87, 0.15);
        float: right;
    }

    span {
        font-weight: bold;
        margin-bottom: 40px;
    }
}

.is-dark {
    background: #292929;
    color: whitesmoke;
}

.is-light {
    background: whitesmoke;
    color: #292929;
}

