.info-Corona {
    background: #ffff;
    border-radius: 10px;
    margin: 10px;
    bottom: 5px;
    position: absolute;
    left: 10px;
    width: 340px;
    padding: 10px;
    box-shadow: 0 0 3px 0 #000;

    a {
        cursor: pointer;
    }
    .legendData {
        padding-bottom: 10px;
        ul {
            li {
                &::before {
                    width: 10px;
                    content: "●";
                    font-size: 1.7em;
                    @media not all and (min-resolution: 0.001dpcm) {
                        width: 10px;
                    }
                    @-moz-document url-prefix() {
                        width: 10px;
                    }
                }
                &:nth-child(1) {
                    &:before {
                        color: #006d2c;
                    }
                }
                &:nth-child(3) {
                    &:before {
                        color: #a50f15;
                    }
                }
                &:nth-child(2) {
                    &:before {
                        color: #f39c12;
                    }
                }
            }
        }
        .info-dataviz {
            p {
                font-size: 12px;
            }
            ul {
                li {
                    display: flex;
                    &::before {
                        color: #000000;
                        font-size: 0.8em;
                    }
                }
            }
        }
    }
}
