.modal {
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    .modal-background {
        opacity: 0.7;
    }

    .modal-content {
        overflow: hidden;
        width: 70%;
        border-radius: 10px;
        height: 95%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #ffffff;
        padding: 20px;

        .modal-description {
            width: 100%;
            height: 25vh;
            svg.recharts-surface {
                cursor: pointer;
            }
        }

        .modal-close::before,
        .modal-close::after {
            background: #363636;
        }
    }

    // LOADING
    .loading {
        text-align: center;

        img {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            margin: 1rem;
            vertical-align: middle;
            border-radius: 100%;
        }
    }
}

.ModalShowing-true {
    opacity: 1;
}

// tooltip Areachart
.custom-tooltip {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 2px solid #c3c3c3;
    border-radius: 10px;

    span {
        font-weight: bold;
    }
}

@media only screen and (max-width: 1023px) {
    .modal {
        .recharts-layer.recharts-brush-texts {
            font-size: 0.7em;
        }
        g.recharts-cartesian-axis-ticks {
            font-size: 0.9em;
        }
        .modal-content {
            width: 95%;
            margin: 0 auto;
            height: 100%;

            .modal-description {
                width: 100%;
                height: 180px;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .modal {
        .modal-content {
            .modal-description {
                height: 140px;
            }
        }
    }
}

.tabs {
    display: block;
    a {
        cursor: pointer;
    }
}

.selected {
    border-bottom: 1px solid black !important;
}
